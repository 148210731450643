.imageContainer {
  width: 100vw;
  height: 100vh;
}

.imageContainer .image-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.image-header .image-buttons {
  display: flex;
  gap: 10px;
}

.image-header .image-buttons .image-button {
  padding: 10px 32px;
  border: none;
  width: 144px;
  border-radius: 17px;
  cursor: pointer;
  border: 2px solid #6d35eb;
  color: #6d35eb;
  background-color: transparent;
}
.image-header .image-buttons .save-button {
  background-color: #6d35eb;
  color: #e6e6e6;
}

.logo-container {
  height: 70px;
}

.image-body {
  height: calc(100% - 90px);
  display: flex;
}
.image-body .image-body-left {
  min-width: 400px;
  max-width: 420px;
  padding: 24px;
  gap: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.image-body .image-body-left textarea {
  height: 200px;
  border: 1px solid #e6e6e6;
  padding: 16px;
  font-size: 20px;
  resize: none;
  outline: none;
}
.image-body .image-body-left .image-generate-button {
  padding: 12px 32px;
  border: none;
  border-radius: 17px;
  cursor: pointer;
  background-color: #6d35eb;
  color: #e6e6e6;
  font-size: 18px;
  width: 230px;
  margin: 18px auto;
}

.image-body .image-body-right {
  flex: 1;
  padding: 100px;
  background: #e6e6e6;
  font-size: 16px;
}
.image-body .image-body-right .result {
  width: 100%;
  /* aspect-ratio: 1.5/ 1; */
  max-height: 900px;
  /* border: 1px dashed #6d35eb; */
  border-radius: 8px;
  padding: 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.image-body .image-body-right .result img {
  width: 100%;
  height: 100%;
  max-height: 900px;
  object-fit: contain;
  border-radius: 8px;
}

.image-style .style-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-top: 12px;
}

.image-style .style-list ul li {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #e6e6e6;
  padding: 5px 14px;
  border-radius: 5px;
}

.image-style .style-list ul li:hover {
  background-color: #6d35eb;
  color: #e6e6e6;
}

.image-style .style-list .selected {
  background-color: #6d35eb;
  color: #e6e6e6;
}

.image-body-left .uploaded-image-container {
  width: 300px;
  height: 300px;
  margin-bottom: 24px;
}

.image-body-left .uploaded-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.image-body-left .uploaded-image-container input {
  width: 100%;
  margin: 12px 0;
  line-height: 20px;
  padding: 3px;
}

@font-face
{
    font-family: RobotoBlack;
    src: url('../fonts/Roboto-Black.woff')
}
@font-face
{
    font-family: Roboto Black;
    src: url('../fonts/Roboto-Black.woff')
}
@font-face
{
    font-family: RobotoBold;
    src: url('../fonts/Roboto-Bold.woff')
}@font-face
{
    font-family: Roboto Bold;
    src: url('../fonts/Roboto-Bold.woff')
}
@font-face
{
    font-family: RobotoLight;
    src: url('../fonts/Roboto-Light.woff')
}@font-face
{
    font-family: Roboto Light;
    src: url('../fonts/Roboto-Light.woff')
}
@font-face
{
    font-family: RobotoRegular;
    src: url('../fonts/Roboto-Regular.woff')
}
@font-face
{
    font-family: Roboto Regular;
    src: url('../fonts/Roboto-Regular.woff')
}
@font-face
{
    font-family: RobotoThin;
    src: url('../fonts/Roboto-Thin.woff')
}
@font-face
{
    font-family: Roboto Thin;
    src: url('../fonts/Roboto-Thin.woff')
}

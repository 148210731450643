.header-box{
    height: 64px;
    transition: background 0.35s;
    background: white;
}

.header-box.header-box-bg{
    background: #6d35eb;
}

.header-box .mian-box{
    max-width: 1300px;
    margin: 0 auto;
    height: 64px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-box .logo{
    width: 160px;

    margin-right: 24px;
    position: relative;
    top: -5px;
}

.header-box .logo img{
    width: 100%;
    vertical-align: bottom;
}

.header-box .logo-and-nemu-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.header-box .menu-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.header-box .menu-item{
    padding: 8px 0 5px 24px;
    transition: color 0.35s;
    color: #6d35eb;
    cursor: pointer;
}

.header-box.header-box-bg .menu-item{
    color: #ffffff;
}

.header-box .login-and-other-box{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.header-box .a-btn{
    margin-left: 24px;

    padding: 4px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    cursor: pointer;
    border: 1px solid #ffffff;
}

.header-box a.a-btn{
    text-decoration: none;
    color: black;
}

.header-box .a-btn svg{
    width: 20px;
}

.main-bg-color{
    background: #6d35eb;
    box-sizing: border-box;
}

.fs-color-fff{
    color: white;
}

.header-box.header-box-bg .out-border-a{
    background: white;
    box-sizing: border-box;
}

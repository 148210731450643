.dm-1-page {
}

.dm-1-page .words-box h1 {
  font-size: 55px;
}

.dm-1-page .words-box p {
  font-size: 18px;
  line-height: 1.35;
}

.dm-1-page .mgb-50 {
  margin-bottom: 50px;
}

.dm-1-page .mgb-40 {
  margin-bottom: 40px;
}

.dm-1-page .mgb-30 {
  margin-bottom: 30px;
}

.dm-1-page .mgb-20 {
  margin-bottom: 20px;
}

.dm-1-page .mgb-10 {
  margin-bottom: 10px;
}

.dm-1-page .a-btn {
  cursor: pointer;
}

.dm-1-page .main-box {
  margin: 0 auto;
  width: 90%;
  max-width: 1320px;
}

.dm-1-page .words-box {
  width: 59%;
  box-sizing: border-box;
  align-self: center;
}

.dm-1-page .media-box {
  width: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dm-1-page .fs26 {
  font-size: 26px;
}

.dm-1-page .fs14 {
  font-size: 14px;
}

.dm-1-page .words-box .a-btn {
}

.dm-1-page .words-box .media-box {
}

.dm-1-page .w-100 {
  width: 100%;
}

.dm-1-page .row-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dm-1-page .row-box-rev {
  flex-direction: row-reverse;
}

.dm-1-page .part01 {
  color: white;
  /* for now, will be changed */
  background-image: url(../../assets/images/backgroundImage.webp);

  /*background: linear-gradient( 135deg, #B535EB 0%, #5F09FF 54%, #4AB7FF 100%);*/

  /* background: #6d35eb; */
  background-position: 50% 100%;
  background-size: 100% 100%;

  box-sizing: border-box;
  padding: 120px 0 250px;
}

.dm-1-page .part01 .words-box {
  max-width: 530px;
}

.dm-1-page .part01 .form-box {
  width: 100%;
  max-width: 600px;
  min-height: 648px;
  background: rgba(255, 255, 255, 0.14);
  border-radius: 25px 25px 25px 25px;
  border: 1px solid #ffffff;

  padding: 30px;
  box-sizing: border-box;
}

.dm-1-page .part01 .form-box-2 {
  background: #ffffff;
  border-radius: 18px 18px 18px 18px;
  margin: 5px auto 0;
  height: calc(100% - 37px);

  box-sizing: border-box;
  padding: 30px 32px 58px;

  color: black;
}

.dm-1-page .part01 .input-box {
  position: relative;
}

.dm-1-page .part01 .input-box-2 {
  border-bottom: 1px solid #5f09ff;
}

.dm-1-page .part01 .input-box-2 > input {
  width: 100%;
  box-sizing: border-box;
  height: 35px;
  border: 0;
  outline: 0;

  font-size: 18px;
  opacity: 0.65;
}

.dm-1-page .part01 .-btn {
  width: 100%;
  max-width: 200px;
  height: 40px;
  background: #6c34eb;
  border-radius: 22px 22px 22px 22px;
  color: #ffffff;

  font-size: 16px;
  border: 0;

  cursor: pointer;
}

.dm-1-page .part02 {
}

.dm-1-page .part02 h3 {
  font-size: 20px;
}

.dm-1-page .part02 p {
  font-size: 16px;
}

.dm-1-page .part02 p img {
  margin-left: 12px;
}

.dm-1-page .part02 .main-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.dm-1-page .part02 .card-box {
  width: calc((100% - 100px) / 3);
  border-radius: 20px;
  overflow: hidden;

  margin-right: 50px;

  margin-bottom: 50px;
}

.dm-1-page .part02 .card-box:nth-of-type(3n + 3) {
  margin-right: 0;
}

.dm-1-page .part02 .top-box {
  height: 99px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 21px;
}

.dm-1-page .part02 .bg-1 {
  background: rgba(108, 52, 235, 1);
}

.dm-1-page .part02 .bg-2 {
  background: rgba(108, 52, 235, 1);
}

.dm-1-page .part02 .cont-box {
  height: 95px;
  background: #f3f4ff;

  display: flex;
  /* flex-direction: column;
  justify-content: space-between; */
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dm-1-page .part02 .cont-box p {
  cursor: pointer;
}

.part02 .title-box {
  display: flex;
  align-items: center;
  gap: 15px;
}
.part02 .title-box h3 {
  font-weight: 500;
  font-size: 20px;
  color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 1100px) {
  .dm-1-page .part01 .row-box {
    flex-direction: column;
  }

  .dm-1-page .part01 .words-box {
    width: 100%;
    max-width: unset;

    margin-bottom: 35px;
  }

  .dm-1-page .part01 .media-box {
    width: 100%;
  }

  .dm-1-page .part01 .form-box {
    max-width: unset;
  }
}

.home-page {
  /*height: 500px;*/
  /*background: #6d35eb;*/
}

.home-page .words-box h1 {
  font-size: 40px;
}

.home-page .words-box p {
  font-size: 18px;
  line-height: 1.35;
}

.home-page .mgb-50 {
  margin-bottom: 50px;
}

.home-page .mgb-30 {
  margin-bottom: 30px;
}

.home-page .mgb-20 {
  margin-bottom: 20px;
}

.home-page .mgb-10 {
  margin-bottom: 10px;
}

.home-page .a-btn {
  cursor: pointer;
}

.home-page .part01 {
  color: white;
  /* for now, will be changed */
  background-image: url(../../assets/images/backgroundImage.webp);

  /* background: #6d35eb; */
  background-position: 50% 100%;
  background-size: 100% 100%;

  box-sizing: border-box;
  padding: 120px 0 150px;
}

.home-page .part01 a.a-btn {
  text-decoration: none;
  color: inherit;
}

.home-page .part01 .a-btn{
    border: 1px solid transparent;
    transition: border 0.35s;
}

.home-page .part01 .a-btn:hover{
    border-color: #6d35eb;
}

.home-page .part01 .words-box h1 {
  font-size: 46px;
}

.home-page .part01 .words-box p {
  font-size: 18px;
}

.home-page .part01 .words-box {
  padding-right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
    margin-top: -2%;

}

.home-page .part01 .media-box {
  /*padding-left: 5%;*/

    position: relative;
}

.home-page .part01 .media-box video{
    width: 100%;
    position: relative;
    right: -10%;
}

.home-page .part02 {
  padding-top: 10px;
}

.home-page .part02 .a-btn {
  border: 0;
  outline: 0;
  background: transparent;
  cursor: pointer;

  font-size: 16px;
  color: #6d35eb;
  /*font-weight: bold;*/
}

.home-page .part02 .a-btn span {
  margin-left: 12px;
  transition: margin-left 0.35s;
}

.home-page .part02 .a-btn:hover span {
  margin-left: 24px;
}

.home-page .part02 .words-box p {
  width: 90%;
}

.home-page .part02 .row-box {
  padding: 100px 0;
  box-sizing: border-box;
}

.home-page .part02 .row-box .words-box {
  padding-right: 5%;
  padding-left: 0;
}

.home-page .part02 .row-box .media-box {
  /*padding-left: 5%;*/
}

.home-page .part02 .row-box-rev .words-box {
  padding-left: 6%;
  padding-right: 0;
}

.home-page .part02 .row-box-rev .media-box {
  /*padding-right: 5%;*/
}

.home-page .part03 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-page .part03 h1 {
  font-size: 44px;
}

.home-page .part03 h2 {
  font-size: 20px;
}

.home-page .part03 p {
  font-size: 14px;
}

.home-page .part03 .image-box {
  position: relative;
  max-width: 600px;

  top: -50px;

  width: 50%;
  margin: 0 auto;
}

.home-page .part03 .a-item {
  position: absolute;
  width: 355px;

  box-sizing: border-box;
  padding-right: 30px;
}

.home-page .part03 .ps-1 {
  top: 60%;
  left: -350px;
}

.home-page .part03 .ps-2 {
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
}

.home-page .part03 .ps-3 {
  top: 50%;
  left: 105%;
}

.home-page .part03 .ps-4 {
  top: 10%;
  left: 105%;
}

.home-page .part03 .main-box{
    /*max-width: 1600px;*/
}

.home-page .main-box {
  margin: 0 auto;
  width: 90%;
  max-width: 1300px;
}

.home-page .words-box {
  width: 59%;
  box-sizing: border-box;
  align-self: center;
}

.home-page .media-box {
  width: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-page .words-box .a-btn {
}

.home-page .words-box .media-box {
}

.home-page .w-100 {
  width: 100%;
}

.home-page .row-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-page .row-box-rev {
  flex-direction: row-reverse;
}

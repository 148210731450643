.footer-box {
    background: #ffffff;
    margin-top: 80px;
}
.footer-box ul{
    list-style: none;
}
.footer-box .main-box {
    width: 80%;
    max-width: 1340px;
    margin: 0 auto;
    padding: 60px 0; }
@media screen and (max-width: 800px) {
    .footer-box .main-box {
        padding: 30px 0 0;
        width: 85%;
    }
}
.footer-box .main-box .box-nav {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between; }
@media screen and (max-width: 800px) {
    .footer-box .main-box .box-nav {
        flex-direction: column; } }
.footer-box .main-box .box-nav .box-contactus {
    width: 270px;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    flex-direction: column; }
@media screen and (max-width: 800px) {
    .footer-box .main-box .box-nav .box-contactus {
        width: 100%;
        margin-bottom: 10px; } }
.footer-box .main-box .box-nav .box-contactus .item-logo img {
    width: 160px;
    margin-bottom: 20px; }
.footer-box .main-box .box-nav .box-contactus .item-logo p {
    color: #6d35eb;
    font-size: 18px;
    font-family: "Roboto Regular";
    letter-spacing: 1px; }
.footer-box .main-box .box-nav .box-contactus .item-link {
    display: -webkit-flex;
    display: flex;
    flex-direction: column; }
@media screen and (max-width: 800px) {
    .footer-box .main-box .box-nav .box-contactus .item-link {
        padding-bottom: 10px;
        border-bottom: 1px solid #e0e0e0; } }
.footer-box .main-box .box-nav .box-contactus .item-link p {
    font-size: 16px;
    color: #000000;
    margin: 4px 0; }
.footer-box .main-box .box-nav .list-link {
    position: relative;
    width: calc(100% - 530px);
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    flex-direction: row; }
@media screen and (max-width: 800px) {
    .footer-box .main-box .box-nav .list-link {
        flex-direction: column;
        width: 100%;
        right: unset; } }
.footer-box .main-box .box-copyright {
    padding: 30px 0 20px;
    text-align: center; }
@media screen and (max-width: 800px) {
    .footer-box .main-box .box-copyright {
        padding: 10px 0 10px; } }
.footer-box .main-box .box-copyright p {
    font-size: 16px;
    font-family: "Roboto Regular";
    color: #9094a6; }
.footer-box .link-item .sw-pc {
    display: inherit; }
.footer-box .link-item .sw-sm {
    display: none; }
.footer-box .link-item .link-title {
    color: #6d35eb;
    font-size: 18px;
    margin-bottom: 25px; }
@media screen and (max-width: 800px) {
    .footer-box .link-item .link-title {
        margin-bottom: 10px; } }
.footer-box .link-item .linklist {
    display: -webkit-flex;
    display: flex;
    flex-direction: column; }
@media screen and (max-width: 800px) {
    .footer-box .link-item .linklist {
        margin-bottom: 10px;
        max-width: unset;
        padding-bottom: 10px;
        border-bottom: 1px solid #e0e0e0; } }
.footer-box .link-item .linklist li {
    font-size: 16px;
    color: #000000;
    margin: 7px 0; }
@media screen and (max-width: 800px) {
    .footer-box .link-item .linklist li {
        margin: 8px 0px; } }
.footer-box .link-item .linklist li a {
    color: #000000;
    text-decoration: none; }
.footer-box .social-list {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    max-width: 220px;
    margin-left: 0px;
    margin-top: 60px; }
.footer-box .social-list li {
    font-size: 14px;
    color: #000000;
    margin: 0px 10px 0 0; }
.footer-box .social-list li a {
    color: #000000;
    text-decoration: none; }

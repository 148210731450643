/* App.css */

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #ffff; /* Light grey background */
}

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
  min-height: 710px;
  width: 50%;
  margin: 0 auto;
  background: rgba(242, 246, 250, 0.85);
  border-radius: 19px;
  padding: 18px;
  margin-top: 32px;
}

.chat-header {
  background: rgba(15, 45, 81, 1);
  color: #ffffff;
  border-radius: 35px;
  width: 50vw;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-logo {
  height: 40px;
  margin-left: 32px;
}

.chat-box {
  flex-grow: 1;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  position: relative;
}

.chat-box .introductionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 9vh;
  gap: 32px;
}

.introductionContainer .ai-Icon {
  width: 120px;
  height: 120px;
}
.introductionContainer .introduction {
  text-align: center;
  width: 70%;
}
.introduction h1 {
  font-size: 30px;
  font-weight: 500;
}
.introduction p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(16, 24, 40, 0.75);
}

.questionContainer {
  background: rgba(255, 255, 255, 1);
  padding: 12px 16px;
  border-radius: 17px;
  position: absolute;
  bottom: 26px;
  left: 22px;
  right: 22px;
}
.questionContainer h3 {
  font-size: 15px;
  font-weight: 500;
  color: rgba(16, 24, 40, 1);
}
.questionContainer .row1 {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.row1 p {
  font-size: 12px;
  line-height: 32px;
  color: rgba(16, 24, 40, 0.75);
  width: 45%;
  min-width: 300px;
  background: rgba(242, 244, 247, 0.4);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.row1 p img {
  width: 22px;
  height: 22px;
  margin-left: 8px;
}
.row1 p:hover {
  background: rgba(242, 244, 247, 0.9);
}
.row2 p img {
  width: 22px;
  height: 22px;
  margin-left: 8px;
}
.row2 p:hover {
  background: rgba(242, 244, 247, 0.9);
}
.row2 p {
  font-size: 12px;
  line-height: 32px;
  color: rgba(16, 24, 40, 0.75);
  width: 45%;
  min-width: 300px;
  background: rgba(242, 244, 247, 0.4);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.questionContainer .row2 {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}
.ai-Icon img {
  width: 100%;
  height: 100%;
}

.chat-message {
  margin: 10px 0;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 100%;
  line-height: 1.4;
}

.chat-input {
  border-radius: 35px;
  padding: 10px;
  width: 98%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
}

.chat-input button {
  width: 55px;
  height: 45px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
.chat-input button img {
  width: 100%;
  height: 100%;
}

.chatContainer {
  display: flex;
}

.chatContainer .user-message {
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}

.chat-container .ai-message {
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: flex-start;
}

.message-content-user {
  background-color: #ffff;
  padding: 0px 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.message-content-ai {
  background-color: #ffff;
  padding: 0px 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.listOfAgent {
  position: absolute;
  left: 8px;
  top: 24px;
  height: 80%;
  width: 250px;
  overflow-y: scroll;
}
.listOfAgent ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.listOfAgent li {
  list-style-type: none;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  line-height: 24px;
  padding: 8px 18px;
}

.listOfAgent li:hover {
  background: rgba(242, 244, 247, 0.9);
  cursor: pointer;
}
.loadingScreen {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgba(242, 246, 250, 0.85);
  z-index: 999;
}

.speakingContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 99px;
  width: 50vw;
  transform: translateX(-50%);
  height: 750px;
  left: 50%;
  gap: 8px;
  background-color: rgba(242, 246, 250, 1);
}
.speakingContainer img {
  width: 130px;
  height: 130px;
}
.speakingContainer h1 {
  font-size: 24px;
  font-weight: 500;
  color: rgba(16, 24, 40, 1);
}
.speakingContainer button {
  background: rgba(15, 45, 81, 1);
  color: #ffffff;
  border-radius: 35px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  display: block;
}

.speakingContainer .textBox {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 35px;
}

.textBox .transcript {
  color: rgba(15, 45, 81, 1);
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  border-radius: 17px;
  margin-top: 12px;
  display: flex;
  gap: 8px;
}

#voiceButton {
  width: 70px;
  height: 60px;
  padding-right: 1;
}
#voiceButton img {
  width: 100%;
  height: 100%;
}
